/**
 * Checks env variable and validate it as a string
 *
 * If the value is undefined or invalid, `defaultValue` will be used instead
 *
 * @param envVar - Env variable
 * @param [defaultValue] - Default value
 * @param validator - Function to validate env var
 * @returns String value
 */
export const getEnvVar = (envVar, defaultValue, validator) => {
    const fallbackValue = defaultValue !== null && defaultValue !== void 0 ? defaultValue : undefined;
    if (!envVar) {
        return fallbackValue;
    }
    if (!validator) {
        return envVar;
    }
    return validator(envVar) ? envVar : fallbackValue;
};
