import { GeneService } from 'Common/services/Gene';
import { PhenotypeService } from 'Common/services/Phenotype';
import { VariantService } from 'Common/services/Variant';
import { CollapsingModelService } from 'Common/services/CollapsingModel';
import { PhenotypicCategoryService } from 'Common/services/PhenotypicCategory';
import { ECollapsingModelKind } from 'API/resources/collapsingModels/types';
export const datasetVersionIdMock = '9de58d05-d2ce-46eb-a198-554e0480378c';
export const phenotypeNameMock = '41270#BlockY83-Y84#Y83-Y84 Surgical and other medical procedures as the cause of abnormal reaction of the patient| or of later complication| without mention of misadventure at the time of the procedure.';
export const phenotypeIdMock = '4de58d05-d2ce-46eb-a198-554e0480379c';
export const phenotypeMock = PhenotypeService.create(phenotypeNameMock, phenotypeIdMock);
export const geneNameMock = 'A1BG';
export const geneIdMock = 'A1BG'; // temporary id = name
export const geneEnsembleIdMock = 'ENSG00000012048';
export const geneMock = GeneService.create(geneNameMock);
export const variantIdMock = '554e0489-d2ce-46eb-a198-4de58d05247c';
export const variantNameMock = '22-43928847-C-G';
export const variantTypeMock = 'SNV';
export const variantMock = VariantService.create(variantIdMock, variantNameMock, variantTypeMock);
// TODO replaced by src/api/resources/collapsingModels/mocks
export const collapsingModelIdsMock = [
    '276bbb38-d10f-4a4a-882b-b22f056ec13e',
    '6b555c62-b960-4f67-9623-f27bbd58e520',
    '5bc0cd5d-4e55-4e99-bf03-52e12f826958',
];
export const phenotypeIdsMock = [
    '4de58d05-d2ce-46eb-a198-554e0480379c',
    '5bc0cd5d-4e55-4e99-bf03-52e12f826014',
    '5bc0cd5d-4e55-4e99-bf03-52e12f826016',
    '5bc0cd5d-4e55-4e99-bf03-52e12f826017',
    '5bc0cd5d-4e55-4e99-bf03-52e12f826018',
    '5bc0cd5d-4e55-4e99-bf03-52e12f826019',
];
export const phenotypeCategoryIdsMock = [
    '59634be6-acaa-43f4-befa-2a08789eaace',
    '59634be6-acaa-43f4-befa-2a08789eaacf',
];
export const collapsingModelIdMock = collapsingModelIdsMock[0];
export const collapsingModelNameMock = 'ptv_all';
export const collapsingModelDescriptionMock = 'Flexible damaging nonsynonymous, moderately rare';
export const collapsingModelKindMock = ECollapsingModelKind.Gene;
export const collapsingModelMock = CollapsingModelService.create(collapsingModelIdMock, collapsingModelNameMock, collapsingModelDescriptionMock, collapsingModelKindMock);
export const phenotypicCategoryIdMock = '59634be6-acaa-43f4-befa-2a08789eaace';
export const phenotypicCategoryNameMock = 'Chapter VII Diseases of the eye and adnexa';
export const phenotypicCategoryShortNameMock = 'Eye and adnexa diseases';
export const phenotypicCategoryMock = PhenotypicCategoryService.create(phenotypicCategoryNameMock, phenotypicCategoryShortNameMock, phenotypicCategoryIdMock);
export const geneAssociationIdMock = 'fe92aace-2370-4235-a45b-436f622af63a';
export const quantileIdMock = 'de73aace-2370-4235-a45b-436f622af63b';
export const mtrResponseMock = { mtr_scores: [0.1, 0.5, 1], transcript_id: '' };
