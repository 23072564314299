var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { v4 as uuidv4 } from 'uuid';
import { FetchError, HTTPErrorFactory, HTTPUnauthorizedError, InvalidJSONResponseError, InvalidResponseError, } from 'API/errors';
/**
 * This base class provides custom network interface for communication with API
 */
export class RESTResource {
    constructor(URLFactory, method = 'get', paramsMapper, handleError) {
        this.URLFactory = URLFactory;
        this.method = method;
        this.paramsMapper = paramsMapper;
        this.handleError = handleError;
    }
    /**
     * Fetches response from API
     *
     * @protected
     * @async
     *
     * @throws FetchError
     * @throws HTTPError
     *
     * @param URL - URL of the resource to fetch data from
     * @param [body] - Additional data which will be used in url's query
     * @param [signal] - Abort signal to cancel requests
     * @returns Fetch response object
     */
    fetchResponse(URL, body, signal) {
        return __awaiter(this, void 0, void 0, function* () {
            let data;
            if (body) {
                data = typeof body === 'string'
                    ? body
                    : JSON.stringify(this.mapBodyParams(body));
            }
            let response;
            try {
                response = yield fetch(URL, {
                    method: this.method,
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'X-Portal-Request-Id': uuidv4(),
                    }),
                    body: data,
                    signal,
                });
            }
            catch (err) {
                throw new FetchError(err);
            }
            if (!response.ok) {
                throw HTTPErrorFactory.createFromResponse(response);
            }
            return response;
        });
    }
    /**
     * Fetches JSON data from API
     *
     * Uses `fetchResponse` method for fetching response,
     * and then gets response's payload from `data` property
     *
     * @protected
     * @async
     *
     * @throws InvalidJSONResponseError
     * @throws InvalidResponseError
     *
     * @param URL - URL of the resource to fetch data from
     * @param [body] - Additional data which will be used in url's query
     * @param [signal] - Abort signal to cancel requests
     * @returns Fetch data object
     */
    fetchJSON(URL, body, signal) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const response = yield this.fetchResponse(URL, body, signal);
            let responseJSON;
            try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                responseJSON = yield response.json();
            }
            catch (err) {
                throw new InvalidJSONResponseError(err, response);
            }
            // until pfam data comes wrapped in data prop
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            if (!((responseJSON === null || responseJSON === void 0 ? void 0 : responseJSON.data) || responseJSON)) {
                throw new InvalidResponseError();
            }
            // until pfam data comes wrapped in data prop
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            return ((_a = responseJSON.data) !== null && _a !== void 0 ? _a : responseJSON);
        });
    }
    /**
     * Builds URL query string from search params
     *
     * @protected
     *
     * @param searchParams - Search params
     * @returns URL query string
     */
    getURLQuery(searchParams) {
        const queryParams = Object.keys(searchParams)
            .reduce((acc, searchParamName) => {
            var _a, _b;
            let searchParam = searchParams[searchParamName];
            if (searchParam === undefined) {
                return acc;
            }
            const queryParamName = (_b = (_a = this.paramsMapper) === null || _a === void 0 ? void 0 : _a[searchParamName]) !== null && _b !== void 0 ? _b : searchParamName;
            if (Array.isArray(searchParam)) {
                return [
                    ...acc,
                    ...searchParam.map((searchParamValue) => {
                        const queryParam = (typeof searchParamValue !== 'string')
                            ? searchParamValue.toString()
                            : searchParamValue;
                        return [queryParamName, queryParam];
                    }),
                ];
            }
            if (typeof searchParam !== 'string') {
                searchParam = searchParam.toString();
            }
            return [...acc, [queryParamName, searchParam]];
        }, []);
        const searchParamsInstance = new URLSearchParams(queryParams);
        searchParamsInstance.sort();
        return searchParamsInstance.toString();
    }
    /**
     * Builds URL string from URL params, factory and search params
     *
     * @param urlParams - URL params
     * @param [searchParams] - search params for URL query
     * @returns URL string
     */
    getURL(urlParams, searchParams) {
        let URL = this.URLFactory(urlParams);
        let URLQuery;
        if (searchParams) {
            URLQuery = this.getURLQuery(searchParams);
        }
        if (URLQuery) {
            URL = `${URL}?${URLQuery}`;
        }
        return URL;
    }
    mapBodyParams(body) {
        if (!this.paramsMapper) {
            return body;
        }
        return Object.keys(body).reduce((acc, key) => {
            var _a, _b;
            const mappedKey = (_b = (_a = this.paramsMapper) === null || _a === void 0 ? void 0 : _a[key]) !== null && _b !== void 0 ? _b : key;
            return Object.assign(Object.assign({}, acc), { 
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                [mappedKey]: body[key] });
        }, {});
    }
    /**
     * Requests and returns the data from external API
     *
     * If the error is thrown and error handler is provided,
     * it will be called instead of throwing original error
     *
     * @async
     *
     * @throws APIError
     *
     * @param urlParams - URL params used by factory to build the URL
     * @param [searchParams] - search params used in URL query
     * @param [body] - body of the request
     * @param [signal] - Abort signal to cancel requests
     * @returns Promise with response JSON
     */
    getData(urlParams, searchParams, body, signal) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = this.getURL(urlParams, searchParams);
            let responseData;
            try {
                responseData = yield this.fetchJSON(url, body, signal);
            }
            catch (err) {
                if (err instanceof HTTPUnauthorizedError
                    && FEATURE_FLAGS.REFRESH_ON_UNAUTHORIZED_ENABLED) {
                    window.location.reload();
                }
                if (!this.handleError) {
                    throw err;
                }
                responseData = this.handleError(err, urlParams, searchParams);
            }
            return responseData;
        });
    }
}
