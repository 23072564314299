import { variantIdMock, variantNameMock, variantTypeMock, } from 'Common/mocks';
import { binaryQVResponseMock, continuousQVResponseMock } from 'API/resources/qv';
import { BinaryVariantsDataType, ContinuousVariantsDataType } from 'QVView/types';
import { QBinaryVariantService } from '../QBinaryVariant/QBinaryVariant.service';
import { QVariantService } from '../QVariant/QVariant.service';
import { QContinuousVariantService } from '../QContinuousVariant/QContinuousVariant.service';
export const baseQVariantMock = {
    id: variantNameMock,
    variant_id: variantIdMock,
    variant_name: variantNameMock,
    variant_type: variantTypeMock,
    consequence_type: 'stop_gained',
    transcript_id: 'ENST00000305877',
    cdna_change: 'c.34A>T',
    aa_change: 'p.Lys12*',
    exon_rank: '1/23',
    missing_genotype: 100000,
};
export const binaryQVariantMock = Object.assign(Object.assign({}, baseQVariantMock), { homo_genotype_major_cases: 1, hetero_genotype_cases: 4, homo_genotype_minor_cases: 2, minor_allele_cases: 2, homo_genotype_major_controls: 1, hetero_genotype_controls: 1, homo_genotype_minor_controls: 7, minor_allele_controls: 1 });
export const continuousQVariantMock = Object.assign(Object.assign({}, baseQVariantMock), { homo_genotype_major: 1, hetero_genotype: 4, homo_genotype_minor: 2, minor_allele: 2 });
const collectionBinaryMock = binaryQVResponseMock.variants.reduce((acc, variant) => {
    const qVariant = QBinaryVariantService.create(variant);
    return Object.assign(Object.assign({}, acc), { [qVariant.id]: qVariant });
}, {});
export const binaryQVariantsCollectionMock = {
    collection: collectionBinaryMock,
    order: Object.keys(collectionBinaryMock),
};
export const binaryPlotVariantsDataMock = {
    [BinaryVariantsDataType.Case]: Object.values(collectionBinaryMock)
        .map((variant) => ({
        codon: QVariantService.getAminoAcidNumber(variant),
        count: QBinaryVariantService.getNumberOfQualifyingCases(variant),
        id: variant.id,
        impactLevel: QVariantService.getImpactLevel(variant.consequence_type),
        isSplice: QVariantService.isSplice(variant),
    })),
    [BinaryVariantsDataType.Control]: Object.values(collectionBinaryMock)
        .map((variant) => ({
        codon: QVariantService.getAminoAcidNumber(variant),
        count: QBinaryVariantService.getNumberOfQualifyingControls(variant),
        id: variant.id,
        impactLevel: QVariantService.getImpactLevel(variant.consequence_type),
        isSplice: QVariantService.isSplice(variant),
    })),
    [ContinuousVariantsDataType.Variants]: [],
};
const collectionContinuousMock = continuousQVResponseMock.variants.reduce((acc, variant) => {
    const qVariant = QContinuousVariantService.create(variant);
    return Object.assign(Object.assign({}, acc), { [qVariant.id]: qVariant });
}, {});
export const continuousQVariantsCollectionMock = {
    collection: collectionContinuousMock,
    order: Object.keys(collectionContinuousMock),
};
export const continuousPlotVariantsDataMock = {
    [BinaryVariantsDataType.Case]: [],
    [BinaryVariantsDataType.Control]: [],
    [ContinuousVariantsDataType.Variants]: Object.values(collectionContinuousMock)
        .map((variant) => ({
        codon: QVariantService.getAminoAcidNumber(variant),
        count: QContinuousVariantService.getNumberOfQualifyingParticipants(variant),
        id: variant.id,
        impactLevel: QVariantService.getImpactLevel(variant.consequence_type),
        isSplice: QVariantService.isSplice(variant),
    })),
};
