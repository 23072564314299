import { v4 as uuidv4 } from 'uuid';
/**
 * Class service with static methods for work with Phenotype
 */
export class PhenotypeService {
    /**
     * Gets a Phenotype object
     * @static
     * @param phenotype - Origin phenotype name
     * @param id - Custom id for phenotype (uuid v4 is used by default)
     * @returns Phenotype object
     */
    static create(phenotype, id) {
        const generateID = id || uuidv4();
        return {
            id: id || generateID,
            /**
             * Temporary solution because of the backend structure
             * @todo Make property optional
             */
            name: phenotype || generateID,
        };
    }
    /**
     * Gets an empty Phenotype object
     * @static
     * @returns Empty Phenotype object
     */
    static get empty() {
        return {
            id: '',
            name: '',
        };
    }
}
