import { logger } from 'Common/utils/logger/logger';
import { QVariantService } from '../QVariant/QVariant.service';
/**
 * Class service with static methods for work with QBinaryVariant
 */
export class QBinaryVariantService {
    /**
     * Creates an object of binary QV
     *
     * @param variantData - Original data received from API
     * @returns QBinaryVariant object
     */
    static create(variantData) {
        return Object.assign(Object.assign({}, QVariantService.create(variantData)), { homo_genotype_major_cases: variantData.homo_genotype_major_cases, hetero_genotype_cases: variantData.hetero_genotype_cases, homo_genotype_minor_cases: variantData.homo_genotype_minor_cases, minor_allele_cases: variantData.minor_allele_cases, homo_genotype_major_controls: variantData.homo_genotype_major_controls, hetero_genotype_controls: variantData.hetero_genotype_controls, homo_genotype_minor_controls: variantData.homo_genotype_minor_controls, minor_allele_controls: variantData.minor_allele_controls });
    }
    /**
     * Gets the number of qualifying cases from the variant
     *
     * @param variant - Original QVariant data received from API
     * @returns The number of qualifying cases
     */
    static getNumberOfQualifyingCases(variant) {
        var _a, _b;
        if (variant.hetero_genotype_cases === null && variant.homo_genotype_minor_cases === null) {
            logger.warn('Both `hetero_genotype_cases` and `homo_genotype_minor_cases` are empty - number of qualifying cases will be set to 0');
        }
        return ((_a = variant.hetero_genotype_cases) !== null && _a !== void 0 ? _a : 0) + ((_b = variant.homo_genotype_minor_cases) !== null && _b !== void 0 ? _b : 0);
    }
    /**
     * Gets the number of qualifying controls from the variant
     *
     * @param variant - Original QVariant data received from API
     * @returns The number of qualifying controls
     */
    static getNumberOfQualifyingControls(variant) {
        var _a, _b;
        if (variant.hetero_genotype_controls === null
            && variant.homo_genotype_minor_controls === null) {
            logger.warn('Both `hetero_genotype_controls` and `homo_genotype_minor_controls` are empty - number of qualifying controls will be set to 0');
        }
        return ((_a = variant.hetero_genotype_controls) !== null && _a !== void 0 ? _a : 0) + ((_b = variant.homo_genotype_minor_controls) !== null && _b !== void 0 ? _b : 0);
    }
}
