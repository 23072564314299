import { EConsequenceType, EImpactLevel } from 'Shared/plots/types';
import { IMPACT_LEVELS_BY_TYPE } from 'Shared/components/ImpactDescriptions/consts';
import { logger } from 'Common/utils/logger/logger';
/**
 * Class service with static methods for work with QVariant
 */
export class QVariantService {
    /**
    * Checks if the given consequenceType is a splice one
    *
    * @param variant - QV variant instance
    * @returns boolean value
    */
    static isSplice(variant) {
        const consequenceType = variant.consequence_type;
        return consequenceType === EConsequenceType.SpliceAcceptorVariant
            || consequenceType === EConsequenceType.SpliceDonorVariant;
    }
    /**
    * Gets the impact level of variant by consequence type
    *
    * @param consequenceType - QV variant  consequence type
    * @returns impact level value
    */
    static getImpactLevel(consequenceType) {
        const impactLevel = IMPACT_LEVELS_BY_TYPE[consequenceType];
        if (impactLevel === undefined) {
            logger.warn(`consequence_type ${consequenceType} for QV lollipop plot is invalid!`);
        }
        return impactLevel !== null && impactLevel !== void 0 ? impactLevel : EImpactLevel.Low;
    }
    /**
     * Creates QVariant object
     *
     * @param variantData - Original QVariant data received from API
     * @returns QVariant object
     */
    static create(variantData) {
        return {
            id: variantData.variant_name,
            variant_id: variantData.variant_id || variantData.variant_name,
            variant_name: variantData.variant_name,
            variant_type: variantData.variant_type,
            consequence_type: variantData.consequence_type,
            transcript_id: variantData.transcript_id,
            cdna_change: variantData.cdna_change,
            aa_change: variantData.aa_change,
            exon_rank: variantData.exon_rank,
            missing_genotype: variantData.missing_genotype,
        };
    }
    /**
     * Gets Amino acid number from the variant
     *
     * @param variant - Original QVariant data received from API
     * @returns Amino acid number
     */
    static getAminoAcidNumber(variant) {
        if (QVariantService.isSplice(variant)) {
            const acidNumberMatch = /^c\.(\d+)/.exec(variant.cdna_change);
            if (!acidNumberMatch || acidNumberMatch.length < 2) {
                return 0;
            }
            return Math.ceil(parseInt(acidNumberMatch[1], 10) / 3);
        }
        const acidNumberMatch = /\d+/.exec(variant.aa_change);
        if (!acidNumberMatch || acidNumberMatch.length === 0) {
            return 0;
        }
        return parseInt(acidNumberMatch[0], 10);
    }
}
