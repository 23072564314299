import { logger } from 'Common/utils/logger/logger';
import { QVariantService } from '../QVariant/QVariant.service';
/**
 * Class service with static methods for work with QContinuousVariant
 */
export class QContinuousVariantService {
    /**
     * Creates an object of binary QV
     *
     * @param variantData - Original data received from API
     * @returns QContinuousVariant object
     */
    static create(variantData) {
        return Object.assign(Object.assign({}, QVariantService.create(variantData)), { homo_genotype_major: variantData.homo_genotype_major, hetero_genotype: variantData.hetero_genotype, homo_genotype_minor: variantData.homo_genotype_minor, minor_allele: variantData.minor_allele });
    }
    /**
     * Gets the number of qualifying participants from the variant
     *
     * @param variant - Original QVariant data received from API
     * @returns The number of qualifying participants
     */
    static getNumberOfQualifyingParticipants(variant) {
        var _a, _b;
        if (variant.hetero_genotype === null && variant.homo_genotype_minor === null) {
            logger.warn('Both `hetero_genotype` and `homo_genotype_minor` are empty - number of qualifying participants will be set to 0');
        }
        return ((_a = variant.hetero_genotype) !== null && _a !== void 0 ? _a : 0) + ((_b = variant.homo_genotype_minor) !== null && _b !== void 0 ? _b : 0);
    }
}
