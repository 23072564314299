import { HTTPServerError } from './HTTPServerError';
import { HTTPInternalServerError } from './HTTPInternalServerError';
export class HTTPServerErrorFactory {
    static create(status, statusText) {
        HTTPServerError.validate(status);
        switch (status) {
            case 500 /* InternalServerError */:
                return new HTTPInternalServerError();
            default:
                return new HTTPServerError(status, statusText);
        }
    }
}
