import { EConsequenceType } from 'Shared/plots/types';
export const phenotypeStatsMock = {
    cases: 2932,
    controls: 9991,
    size: null,
    pvalue: 0.044996091480126364,
    oddsRatio: 472945586,
    participants: 2035,
    consequenceTypes: [
        EConsequenceType.StopGained,
        EConsequenceType.BidirectionalGeneFusion,
        EConsequenceType.ProteinAlteringVariant,
    ],
};
