import { response as datasetsResponseMock, UKB300kDatasetResponseMock } from 'API/resources/datasets';
export const datasetsMock = datasetsResponseMock.map(({ version_id, version_name, metadata, provider, }) => ({
    id: version_id,
    name: version_name,
    description: metadata,
    versionId: version_id,
    providerId: provider,
}));
export const datasetsExtendedMock = [...datasetsMock, {
        id: UKB300kDatasetResponseMock.version_id,
        name: UKB300kDatasetResponseMock.version_name,
        description: UKB300kDatasetResponseMock.metadata,
        versionId: UKB300kDatasetResponseMock.version_id,
        providerId: UKB300kDatasetResponseMock.provider,
    }];
