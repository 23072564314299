import { v4 as uuidv4 } from 'uuid';
import { ELevelResult } from 'Shared/types';
import { logger } from 'Common/utils/logger/logger';
import { ECollapsingModelKind } from 'API/resources/collapsingModels/types';
/**
 * Class service with static methods for work with CollapsingModel
 */
export class CollapsingModelService {
    /**
     * Gets a ELevelResult value
     *
     * @static
     * @param kind - Kind of collapsing model
     * @param defaultLevelResult - default value of level result if kind is invalid or not provided
     * @returns ELevelResult value
     */
    static getLevelResult(kind, defaultLevelResult = ELevelResult.Gene) {
        if (kind === ECollapsingModelKind.Gene) {
            return ELevelResult.Gene;
        }
        if (kind === ECollapsingModelKind.Variant) {
            return ELevelResult.Variant;
        }
        if (kind === ECollapsingModelKind.Phenotype) {
            return ELevelResult.Phenotype;
        }
        logger.warn(`kind value: ${kind} is invalid! ${defaultLevelResult} will be set by default`);
        return defaultLevelResult;
    }
    /**
     * Gets a CollapsingModel object
     *
     * @static
     * @param id - Custom id for CollapsingModel (uuid4 v4 is used by default)
     * @param name - Origin CollapsingModel name
     * @param description - CollapsingModel description
     * @param kind - Kind of collapsing model
     * @returns CollapsingModel object
     */
    static create(id = uuidv4(), name, description, kind) {
        return {
            id,
            name: name !== null && name !== void 0 ? name : id,
            description: description !== null && description !== void 0 ? description : null,
            levelResult: CollapsingModelService.getLevelResult(kind, ELevelResult.Gene),
        };
    }
    /**
     * Gets an empty CollapsingModel object
     *
     * @static
     * @returns Empty CollapsingModel object
     */
    static get empty() {
        return {
            id: '',
            name: '',
            description: null,
            levelResult: ELevelResult.Gene,
        };
    }
}
