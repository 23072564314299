import { v4 as uuidv4 } from 'uuid';
/**
 * Class service with static methods for work with PhenotypicCategory
 */
export class PhenotypicCategoryService {
    /**
     * Gets a PhenotypicCategory object
     * @static
     * @param name - The name of the phenotypic category
     * @param shortName - The short name of the phenotypic category
     * @param id - The id of the phenotypic category
     * @returns PhenotypicCategory object
     */
    static create(name, shortName, id = uuidv4()) {
        return {
            id,
            name,
            shortName: shortName !== null && shortName !== void 0 ? shortName : null,
        };
    }
    /**
     * Gets an empty PhenotypicCategory object
     * @static
     * @returns Empty PhenotypicCategory object
     */
    static get empty() {
        return {
            id: '',
            name: '',
            shortName: null,
        };
    }
}
