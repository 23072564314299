/**
 * Checks env variable and tries to convert it into number value
 *
 * If the value is undefined or cann't be converted into a finit number,
 * `defaultValue` will be used instead
 *
 * @param envVar - Env variable
 * @param defaultValue - Default value
 * @param [format] - Formatting rules (the same object which is used in `.toLocalString`)
 * @returns Number value
 */
export const getEnvNumber = (envVar, defaultValue, format) => {
    if (!envVar) {
        return defaultValue;
    }
    const parsedNumber = parseFloat(envVar);
    if (Number.isNaN(parsedNumber) || !Number.isFinite(parsedNumber)) {
        return defaultValue;
    }
    return format ? parseFloat(parsedNumber.toLocaleString('en-GB', format)) : parsedNumber;
};
