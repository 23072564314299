import { APIError } from '../../APIError';
import { HTTPError } from '../HTTPError';
export class HTTPClientError extends HTTPError {
    static validate(status) {
        if (status < 400 || status > 499) {
            throw new APIError(`Cannot create HTTP client error object for a non client error HTTP status ${status}`);
        }
    }
    constructor(status, statusText) {
        HTTPClientError.validate(status);
        super(status, statusText, 'HTTP client error');
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
