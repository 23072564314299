import { HTTPClientError } from './HTTPClientError';
import { HTTPNotFoundError } from './HTTPNotFoundError';
import { HTTPUnauthorizedError } from './HTTPUnauthorizedError';
export class HTTPClientErrorFactory {
    static create(status, statusText) {
        HTTPClientError.validate(status);
        switch (status) {
            case 404 /* NotFound */:
                return new HTTPNotFoundError();
            case 401 /* Unauthorized */:
                return new HTTPUnauthorizedError();
            default:
                return new HTTPClientError(status, statusText);
        }
    }
}
