import { EMockTypes } from 'API/types';
export const getMock = (mockedURL) => (type, delay = 0) => ((fetch) => (Object.entries(mockedURL).reduce((acc, [url, { full, empty, method = 'get' }]) => {
    let response;
    switch (type) {
        case EMockTypes.full:
            response = { body: full, status: 200 };
            break;
        case EMockTypes.empty:
            response = { body: empty, status: 200 };
            break;
        case EMockTypes.clientError:
            response = 404;
            break;
        case EMockTypes.serverError:
            response = 500;
            break;
        default:
            response = 200;
    }
    return acc.mock(url, response, { method, delay });
}, fetch)));
