import { ETraitsTypes, ELevelResult } from 'Shared/types';
import { EConsequenceType } from 'Shared/plots/types';
import { geneMock, phenotypeMock, phenotypicCategoryMock, collapsingModelMock, variantMock, geneAssociationIdMock, datasetVersionIdMock, ancestryMock, } from 'Common/mocks';
const geneAssociationBaseMock = {
    id: geneAssociationIdMock,
    gene: geneMock,
    phenotype: phenotypeMock,
    phenotypicCategory: phenotypicCategoryMock,
    collapsingModel: collapsingModelMock,
    pvalue: 0.0001706196132176885,
    pvalueLog: 3.7679710468929013,
    datasetVersionId: datasetVersionIdMock,
    ancestry: ancestryMock,
};
const geneAssociationGLRCommonMock = {
    levelResult: ELevelResult.Gene,
    samples: 7591,
};
const geneAssociationVLRCommonMock = {
    levelResult: ELevelResult.Variant,
    variant: variantMock,
    transcriptId: 'ENST00000305877',
    CDNAChange: 'c.34A>T',
    AAChange: 'p.Lys12*',
    exonRank: '1/23',
    consequenceType: EConsequenceType.StopGained,
};
export const geneBinaryAssociationGLRMock = Object.assign(Object.assign(Object.assign({}, geneAssociationBaseMock), geneAssociationGLRCommonMock), { traitsType: ETraitsTypes.Binary, cases: 716, controls: 716, caseFrequency: 0.9919231, controlFrequency: 0.65725163, oddsRatio: 0.84, QVCases: 9153, QVControls: 11064395477, oddsRatioLCI: 229411131931.95, oddsRatioUCI: 1139972 });
export const geneContinuousAssociationGLRMock = Object.assign(Object.assign(Object.assign({}, geneAssociationBaseMock), geneAssociationGLRCommonMock), { traitsType: ETraitsTypes.Continuous, caseMedian: 787427063.86, controlMedian: 217199, beta: -83.5801, betaSTD: 4665.84810524, yesQV: 4665, noQV: 4701, oddsRatioLCI: 4701.661203, oddsRatioUCI: 5933433904.67 });
export const geneBinaryAssociationVLRMock = Object.assign(Object.assign(Object.assign({}, geneAssociationBaseMock), geneAssociationVLRCommonMock), { traitsType: ETraitsTypes.Binary, cases: 12300, AACases: 100, ABCases: 200, BBCases: 300, caseMAF: 23.333, ABOrBBCasesPercentage: 11.12345, BBCasesPercentage: 5.54545, controls: 20000, AAControls: 1000, ABControls: 2000, BBControls: 3000, controlMaf: 33.456, ABOrBBControlsPercentage: 20.2222, BBControlsPercentage: 11.1111, oddsRatio: 0.12, oddsRatioLCI: 23.23232, oddsRatioUCI: 45.454545 });
export const geneContinuousAssociationVLRMock = Object.assign(Object.assign(Object.assign({}, geneAssociationBaseMock), geneAssociationVLRCommonMock), { traitsType: ETraitsTypes.Continuous, samples: 12300, noAAGenotypes: 100, noABGenotypes: 200, noBBGenotypes: 300, MAF: 13.33, ABOrBBGenotypes: 14.34, BBGenotypes: 44.44, effectSize: -54, effectSizeStandardError: 100, effectSizeLCI: 20.22, effectSizeUCI: 23.23 });
export const geneContinuousAssociationVLRFinnGenMock = Object.assign(Object.assign({}, geneContinuousAssociationVLRMock), { AAControls: 1000, ABControls: 2000, BBControls: 3000 });
export const emptyGeneContinuousAssociationVLRMock = Object.assign(Object.assign({}, geneBinaryAssociationVLRMock), { samples: null, noAAGenotypes: null, noABGenotypes: null, noBBGenotypes: null, MAF: null, ABOrBBGenotypes: null, BBGenotypes: null, effectSize: null, effectSizeStandardError: null, effectSizeLCI: null, effectSizeUCI: null, variant: {
        id: '',
        name: '',
        type: '',
    }, transcriptId: null, CDNAChange: null, AAChange: null, exonRank: null, gene: {
        id: '',
        name: '',
    }, phenotype: {
        id: '',
        name: '',
    }, phenotypicCategory: {
        id: '',
        name: '',
        shortName: '',
    }, collapsingModel: {
        id: '',
        name: '',
        description: '',
        levelResult: ELevelResult.Gene,
    }, pvalue: null, pvalueLog: null });
export const emptyGeneContinuousAssociationVLRFinnGenMock = Object.assign(Object.assign({}, emptyGeneContinuousAssociationVLRMock), { AAControls: null, ABControls: null, BBControls: null });
