import { APIError } from '../APIError';
export class HTTPError extends APIError {
    constructor(status, statusText, customMessage) {
        let message = `${customMessage !== null && customMessage !== void 0 ? customMessage : 'HTTP error'} ${status}`;
        if (typeof statusText === 'string') {
            message = `${message}: ${statusText}`;
        }
        super(message);
        this.status = status;
        this.statusText = statusText;
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
