import { ETraitsTypes } from 'Shared/types';
import { geneMock, variantMock } from 'Common/mocks';
export const variantBinaryMetadataMock = {
    traitsType: ETraitsTypes.Binary,
    variant: variantMock,
    gene: geneMock,
    consequenceType: 'stop_gained',
    transcriptId: 'ENST00000232892',
    CDNAChange: 'c.34A>T',
    AAChange: 'p.Lys12*',
    exonRank: '1/23',
};
export const variantContinuousMetadataMock = Object.assign(Object.assign({}, variantBinaryMetadataMock), { traitsType: ETraitsTypes.Continuous });
