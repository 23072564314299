export class ArchiveDataError extends Error {
    constructor(error) {
        let errorMessage = 'Cannot archive data due to error';
        if (error) {
            errorMessage = `${errorMessage}: ${error.message}`;
        }
        super(errorMessage);
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
