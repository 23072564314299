import { ELevelResult, ETraitsTypes } from 'Shared/types';
import { variantIdMock, phenotypeMock, phenotypicCategoryMock, collapsingModelMock, variantMock, geneMock, datasetVersionIdMock, ancestryMock, } from 'Common/mocks';
const variantAssociationBase = {
    id: variantIdMock,
    phenotype: phenotypeMock,
    phenotypicCategory: phenotypicCategoryMock,
    collapsingModel: collapsingModelMock,
    variant: variantMock,
    datasetVersionId: datasetVersionIdMock,
    gene: geneMock,
    pvalue: 0.0001706196132176885,
    pvalueLog: 3.7679710468929013,
    consequenceType: 'stop_gained',
    transcriptId: 'ENST00000232892',
    CDNAChange: 'c.34A>T',
    AAChange: 'p.Lys12*',
    exonRank: '1/23',
    ancestry: ancestryMock,
};
export const variantBinaryAssociationMock = Object.assign(Object.assign({}, variantAssociationBase), { traitsType: ETraitsTypes.Binary, geneName: 'AAAS', cases: 12300, AACases: 100, ABCases: 200, BBCases: 300, caseMAF: 23.333, ABOrBBCasesPercentage: 11.12345, BBCasesPercentage: 5.54545, controls: 20000, AAControls: 1000, ABControls: 2000, BBControls: 3000, controlMaf: 33.456, ABOrBBControlsPercentage: 20.2222, BBControlsPercentage: 11.1111, oddsRatio: 0.31231, oddsRatioLCI: 23.23232, oddsRatioUCI: 45.454545 });
export const variantContinuousAssociationMock = Object.assign(Object.assign({}, variantAssociationBase), { traitsType: ETraitsTypes.Continuous, samples: 12300, noAAGenotypes: 100, noABGenotypes: 200, noBBGenotypes: 300, MAF: 13.33, ABOrBBGenotypes: 14.34, BBGenotypes: 44.44, effectSize: -54, effectSizeStandardError: 100, effectSizeLCI: 20.22, effectSizeUCI: 23.23 });
export const variantContinuousAssociationFinnGenMock = Object.assign(Object.assign({}, variantContinuousAssociationMock), { AAControls: 1000, ABControls: 2000, BBControls: 3000 });
export const emptyVariantContinuousAssociationMock = Object.assign(Object.assign({}, variantContinuousAssociationMock), { consequenceType: null, phenotype: {
        id: '',
        name: '',
    }, collapsingModel: {
        id: '',
        name: '',
        description: null,
        levelResult: ELevelResult.Variant,
    }, pvalue: null, AAF: null, noAAGenotypes: null, noABGenotypes: null, noBBGenotypes: null, effectSize: null, effectSizeLCI: null, effectSizeUCI: null, transcriptId: null, CDNAChange: null, AAChange: null, gene: {
        id: '',
        name: '',
    }, AAControls: null, ABControls: null, BBControls: null });
export const emptyVariantContinuousAssociationFinnGenMock = Object.assign(Object.assign({}, emptyVariantContinuousAssociationMock), { AAControls: null, ABControls: null, BBControls: null });
