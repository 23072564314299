/**
 * Class service with static methods for work with Gene
 */
export class GeneService {
    /**
     * Gets a Gene object
     * @static
     * @param name - The name of the Gene
     * @param id - The id of the Gene
     * @returns Gene object
     */
    static create(name, id) {
        return {
            id: id !== null && id !== void 0 ? id : name,
            name,
        };
    }
    /**
     * Gets an empty Gene object
     * @static
     * @returns Empty Gene object
     */
    static get empty() {
        return {
            id: '',
            name: '',
        };
    }
}
