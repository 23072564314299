import { APIError } from '../../APIError';
import { HTTPError } from '../HTTPError';
export class HTTPServerError extends HTTPError {
    static validate(status) {
        if (status < 500 || status > 599) {
            throw new APIError(`Cannot create HTTP client error object for a non server error HTTP status ${status}`);
        }
    }
    constructor(status, statusText) {
        HTTPServerError.validate(status);
        super(status, statusText, 'HTTP server error');
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
