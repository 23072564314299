/**
 * Class service with static methods for work with Variant
 */
export class VariantService {
    /**
     * Gets an Variant object
     * @static
     * @param id - The id of the variant
     * @param name - The name of the variant
     * @param type - The type of the variant
     * @returns Variant object
     */
    static create(id, name, type) {
        return {
            id: id !== null && id !== void 0 ? id : name,
            name,
            type,
        };
    }
    /**
     * Gets an empty Variant object
     * @static
     * @returns Variant object
     */
    static get empty() {
        return {
            id: '',
            name: '',
            type: '',
        };
    }
}
